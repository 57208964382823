.Footer {
  padding-top: 50px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #fafbfc;
}

.Footer>div {
  width: calc(700px / 5);
  color: #828590;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Br1 {
  border-right: solid 1px var(--border-result);
}