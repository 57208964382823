.ResultLeft {
  display: flex;
  justify-content: flex-start;

  position: relative;
}

.ResultLeftBox {
  display: flex;
  font-family: var(--font-sub);
  margin-top: 30px;
}

.rl_common {
  width: 780px;
  margin-right: 20px;

  box-sizing: border-box;

  overflow: hidden;
  background-color: #fff;
  border: var(--border-legoo);

  border-radius: 16px;
}

.n_left_header {
  height: 25px;
}

.n_left_header>.title_btn {
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
}

.n_left_header>.title_btn>div {
  color: #0c43b7;
  margin-left: 15px;
}

.n_left_header>.title_btn>div:hover {
  cursor: pointer;
  text-decoration: underline;
}

.result_left_header_setting.adv {
  border-top: var(--border-legoo);
  margin-top: 15px;
  background-color: #f9fcff;
}

.result_left_header_setting.adv>.news_list>.news_header>.news_name {
  color: #6f85aa;
}

.result_left_header_setting.is_dual_true {
  display: flex;
}

.result_left_dual_l,
.result_left_dual_r {
  display: flex;
  flex-direction: column;

  width: 50%;
}

.place_line {
  display: flex;
  align-items: center;

  overflow: hidden;
  text-overflow: ellipsis;
  word-break: nowrap;
  margin-bottom: 3px;

  cursor: pointer;
  user-select: none;
}

.place_line.isFill_false {
  display: none;
}

.place_0 {
  margin-top: 5px;
  color: #f5683c;
  font-family: var(--font-main);
  word-spacing: 1px;
}

.place_1,
.place_2,
.place_3 {
  color: #666;
  font-family: var(--font-main);
}

.place_1 {
  font-size: 17px;
  cursor: pointer;
}

.place_1:hover {
  text-decoration: underline;
}

.place_btns {
  display: flex;
  align-items: center;
}

.place_btns>.place_btn {
  display: flex;
  align-items: center;

  color: var(--color-high1);
  /* font-family: var(--font-main); */
  font-weight: 600;
  font-size: 14px;

  min-width: 30px;
  padding: 2px 5px;
  box-sizing: border-box;
  border-radius: 5px;

  background-color: #00c5cf15;

  margin-right: 8px;
  user-select: none;

  transition: 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.place_btns>.place_btn:hover {
  cursor: pointer;
  background-color: var(--color-high1);
  color: #dffdff;
}

.place_btns>.place_btn:hover>svg {
  fill: #dffdff !important;
}

.place_btns>.place_btn>svg,
.place_line>.i>svg {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 14px;
  height: 14px;
  fill: var(--color-high1);

  margin-right: 3px;

  transition: 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.place_line>.i>svg {
  padding-right: 4px;
}

.result_left_middleBar {
  display: flex;
  align-items: center;
  padding: 19px;
  padding-bottom: 5px;
}

.result_left_middleBar>.middleBar_btn {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 13px;
  height: 35px;

  box-sizing: border-box;

  border: var(--border-legoo);
  border-radius: 35px;

  margin-right: 10px;

  cursor: pointer;
  user-select: none;

  transition: 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.result_left_middleBar>.middleBar_btn:hover {
  border: solid 1px var(--color-high1);
  background-color: #00c5cf15;
  color: var(--color-high1);
}

.result_left_middleBar>.middleBar_btn.selected {
  border: solid 1px var(--color-high1);
  background-color: #00c5cf15;
  color: var(--color-high1);
  font-weight: 600;
}

.news_content {
  cursor: pointer;
  flex: 1 0 0;
}

.news_content:hover>.news_body_left>.news_title {
  text-decoration: underline;
}