:root {
  --common-header-height: 60px;
}

.CommonHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--common-header-height);
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  font-size: 19px;
  font-weight: 600;
  background-color: #fff;
  box-shadow: var(--box-shadow-3);
  /* text-shadow: 2px 2px 2px #fff; */
  z-index: 10;
}

.CommonHeaderBlank {
  height: var(--common-header-height);
}

.common_header_logo {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 20px;
}

.common_header_logo>img {
  height: 50%;
  margin-right: 10px;
  cursor: pointer;
}

.common_header_logo>span {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 25px;
  line-height: 25px;
  
  height: 35px;

  box-sizing: border-box;

  border-top: solid 0px transparent;
  border-bottom: solid 0px transparent;

  padding-bottom: 2.5px;

  user-select: none;
}

.common_header_btn {
  display: flex;
  align-items: center;
  height: 50px;
  box-sizing: border-box;
  margin-right: 20px;
  border-bottom: solid 2px transparent;
  color: #999;
  cursor: pointer;

  font-size: 16px;
  font-weight: 400;
  font-family: var(--font-main);

  user-select: none;

  transition: all 200ms linear;
}

.common_header_btn.selected {
  color: #222;
  border-bottom: solid 2px #222;
}

.common_header_btn_square {
  display: flex;
  align-items: center;
  height: 80%;
  box-sizing: border-box;
  padding: 10px 20px;
  border: solid 1px var(--border-main);
  font-size: 16px;
  font-family: var(--font-main);
  font-weight: 400;
  margin-left: 10px;
  border-radius: 5px;
  color: #444;

  transition: all 200ms linear;

  user-select: none;
}

.common_header_btn_square:hover {
  cursor: pointer;
  background-color: #E9EEF6;
}

.common_header_btn_square.noBorder {
  border: none;
}

.common_header_btn_square.colorHigh {
  color: var(--color-high2);
}

.common_header_btn_square.boxHigh {
  color: #fff;
  background-color: var(--color-high2);
}

.common_header_btn_square.boxHigh:hover {
  background-color: #0c43b7;
}

.common_header_icon {
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  fill: #222;

  transition: 300ms linear;
}

.common_header_icon>svg>path {
  fill: #222;
}

.common_header_icon:hover {
  cursor: pointer;
  fill: var(--color-high1);
  rotate: 360deg;
}

.common_header_icon:hover>svg>path {
  fill: var(--color-high1);
}

.common_header_icon>svg {
  height: 80%;
}

.common_header_icon.plus>svg {
  height: 90% !important;
}

.common_header_login {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 60px;

  border-left: solid 1px var(--border-main);

  margin-left: 20px;
  padding-left: 20px;
  height: 100%;

  font-size: 14px;
  font-weight: 400;

  font-family: var(--font-main);
}

.common_header_login:hover {
  cursor: pointer;
  color: var(--color-high2);
  text-decoration: underline;
}

.common_search {
  display: flex;
  align-items: center;

  box-sizing: border-box;

  margin-left: 30px;
  padding: 0 10px;
  height: 48px;
  max-width: 500px;
  flex: 1 0 auto;

  background-color: #E9EEF6;

  border-radius: 40px;
}

.Mobile>.common_search {
  padding: 0;
  justify-content: center;
}

.common_search_icon {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 38px;
  width: 38px;
  box-sizing: border-box;
  
  padding: 10px;
  border-radius: 50%;
  overflow: hidden;

  fill: #444;
}

.Mobile>.common_search>.common_search_icon {
  margin: 0 -10px;
}

.common_search_icon:hover {
  cursor: pointer;
  /* fill: var(--color-high1); */
  background-color: #dde0e4;
}

.common_search_icon>svg {
  height: 90%;
}

.common_search_input {
  display: flex;
  align-items: center;

  font-size: 16px;
  height: 100%;

  flex: 1 0 auto;
  box-sizing: border-box;

  overflow: hidden;

  background-color: transparent;
  border: none;
}

.common_search_input:focus {
  outline: none;
}