.CommonSidebar {
  display: flex;
  flex-direction: column;

  height: 100%;
  max-height: 100%;
  width: 180px;
  min-width: 180px;

  box-sizing: border-box;
  padding: 10px;

  overflow: hidden;

  box-shadow: var(--box-shadow-3);
}

.CommonSidebar>.main_btn {
  display: flex;
  align-items: center;
  cursor: pointer;

  user-select: none;

  width: 100%;
  height: 50px;

  box-sizing: border-box;
  border-radius: 10px;

  background-color: #c2e7ff;

  font-size: 16px;
  font-family: var(--font-main);
  font-weight: 600;

  color: #444;
  fill: #444;
}

.CommonSidebar>.main_btn>span {
  display: flex;
  align-items: center;
  justify-content: center;
  
  width: 40px;
  height: 40px;
}

.CommonSidebar>.main_btn>span>svg {
  width: 15px;
  height: 15px;
}

.CommonSidebar>.main_btn:hover {
  box-shadow: var(--box-shadow-4);
  color: #000;
  fill: #000;
}

.CommonSidebar>.list {
  display: flex;
  align-items: center;
  cursor: pointer;

  user-select: none;

  padding: 5px;
  padding-left: 10px;

  border-radius: 20px;
  height: 20px;

  margin-bottom: 5px;
}

.CommonSidebar>.list>span {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  min-width: 15px;
  height: 100%;
  line-height: 10px;

  font-size: 8px;
  color: #999;
}

.CommonSidebar>.list>span>svg {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 15px;
  height: 15px;

  margin-right: 10px;

  font-size: 8px;
  color: #999;
}

.CommonSidebar>.list>.t {
  font-family: var(--font-main);
  color: #222;
  flex: 1 0 0;
}

.CommonSidebar>.list>.p {
  font-family: var(--font-main);
  color: #222;
  font-size: 14px;
  margin-right: 10px;
}

.CommonSidebar>.list:hover {
  background-color: #f5f5f5;
}

.CommonSidebar>.list.selected {
  background-color: #E6F2FE;
}

.CommonSidebar>.list.selected>.t,
.CommonSidebar>.list.selected>.p {
  color: var(--color-high2);
  font-weight: 600;
}

.CommonSidebar>.line {
  display: flex;

  margin-top: 10px;
  margin-bottom: 10px;
  height: 1px;
  background-color: #F8FAFD;
}

.CommonSidebar>.line2 {
  display: flex;

  margin-top: 5px;
  margin-bottom: 5px;
  height: 1px;
  background-color: #F8FAFD;
}