.Cafe {
  width: 1080px;
  margin: 10px auto;

  font-family: var(--font-sub);
}

.Cafe .header {
  display: flex;
  align-items: center;

  width: 100%;
  height: 30px;
  box-sizing: border-box;

  overflow: hidden;
}

.Cafe .header>div {
  transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.Cafe .header>.logo {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  user-select: none;
  cursor: pointer;
}

.Cafe .header>.logo>img {
  height: 100%;
}

.Cafe .header>.typo {
  display: flex;
  align-items: center;

  height: 30px;

  font-family: var(--font-sub);
  font-size: 20px;
  font-weight: 900;
  color: var(--color-high1);
  padding-left: 5px;
  text-shadow: var(--box-shadow-3-hover);

  user-select: none;
  cursor: pointer;
}

.Cafe .header>.menu {
  font-size: 13px;
  color: #333;

  user-select: none;
  cursor: pointer;
}

.Cafe .header>.menu:hover {
  color: var(--color-high1);
}

.Cafe .header>.mb {
  margin: 0 10px;
  width: 1px;
  height: 12px;
  background-color: #ddd;
}

.Cafe .header>.ii {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;

  fill: #777;

  cursor: pointer;
  user-select: none;
}

.Cafe .header>.ii>svg {
  height: 15px;
}

.Cafe .header>.ii.gear>svg {
  fill: #fff;
  stroke: #777;
  height: 20px;
}

.Cafe .header>.ii:hover {
  fill: var(--color-high1);
  /* rotate: 360deg; */
}

.Cafe .banner {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 250px;
  box-sizing: border-box;

  margin: 10px 0;

  overflow: hidden;

  cursor: pointer;
}

.Cafe .banner>img {
  width: 100%;
}

.Cafe .middle {
  display: flex;
  align-items: center;

  width: 100%;
  height: 45px;
  box-sizing: border-box;

  padding: 10px 20px;

  border: solid 1px #ddd;

  margin-bottom: 20px;

  overflow: hidden;
}

.Cafe .middle>.mm {
  display: flex;
  align-items: center;

  font-size: 15px;
  line-height: 18px;
  height: 19px;

  color: #333;
  margin-right: 10px;

  cursor: pointer;
  user-select: none;
}

.Cafe .middle>.mm>span {
  color: #999;
}

.Cafe .middle>.mm>div {
  border-bottom: solid 1px transparent;
  transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.Cafe .middle>.mm:hover>div {
  border-color: #bbb;
}

.Cafe .main {
  display: flex;

  width: 100%;
  box-sizing: border-box;

  overflow-x: hidden;
}

.cf_left {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  width: 200px;

  margin-right: 20px;
}

.cf_left>.info {
  display: flex;
  align-items: center;

  border-top: solid 2px #333;
  border-bottom: solid 1px #ddd;
}

.cf_left>.info>.bb {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100px;
  margin: 10px 0;
  height: 25px;
  box-sizing: border-box;

  border: solid 1px transparent;

  font-size: 15px;
  color: #aaa;

  font-family: var(--font-main);

  user-select: none;
  cursor: pointer;
}

.cf_left>.info>.bb.sel {
  color: #222;
}

.cf_left>.info2 {
  display: flex;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;

  width: 100%;

  padding: 15px 10px;

  border-bottom: solid 1px #ddd;

  margin-bottom: 10px;
}

.cf_left>.info2>.icon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 60px;
  height: 60px;

  margin-right: 10px;
  box-sizing: border-box;
  overflow: hidden;

  border: solid 1px #eee;
  border-radius: 20px;
}

.cf_left>.info2>.icon>img {
  width: 100%;
}

.cf_left .b1.lt {
  letter-spacing: -1.4px;
}

.cf_left>.info2>.more>div {
  display: flex;
  align-items: center;

  font-size: 13px;
  margin-bottom: 2px;

  color: #333;
}

.cf_left>.info2>.more svg {
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;

  width: auto;
  height: 13px;

  margin-right: 5px;

  fill: var(--color-high2);
}

.cf_left>.btn {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 40px;
  border: solid 1px #ddd;
  border-radius: 8px;

  margin-bottom: 10px;

  font-size: 14px;
  color: #222;
  font-family: var(--font-main);

  user-select: none;
  cursor: pointer;

  transition: all 100ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.cf_left>.btn.black {
  color: #fff;
  background-color: #666;
  border-color: #666;
}

.cf_left>.btn:hover {
  color: #fff;
  background-color: #999;
  border-color: #999;
}

.cf_left>.btn:active {
  transition: all 50ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  /* background-color: var(--color-high1);
  border-color: var(--color-high1); */
}

.cf_left>.list {
  display: flex;
  flex-direction: column;

  width: 100%;
  box-sizing: border-box;

  border-top: solid 2px #333;
  border-bottom: solid 2px #333;
}

.cf_left>.list>.block {
  margin-top: 6px;
  border-bottom: solid 1px #bbb;
}

.cf_left>.list>.block svg {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 12px;
  height: 12px;

  margin-bottom: 1px;

  fill: #999;
}

.cf_left>.list>.block .title {
  font-size: 15px;
  font-family: var(--font-main);

  border-top: solid 1px #bbb;
  border-bottom: solid 1px #bbb;

  padding: 6px 4px;

  margin-top: -6px;
  margin-bottom: 6px;
}

.cf_left>.list>.block .bb {
  width: fit-content;

  display: flex;
  align-items: center;

  margin-bottom: 6px;
  padding: 0 12px;

  cursor: pointer;
  user-select: none;
}

.cf_left>.list>.block .bb>.tt {
  width: fit-content;

  margin-left: 8px;
  font-size: 14px;
  color: #333;
  letter-spacing: -.4px;

  transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  border-bottom: solid 1px transparent;
}

.cf_left>.list>.block .bb:hover>.tt {
  border-color: #666;
  color: #000;
}

.cf_right {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  width: 860px;
}

.bodyimg {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  border: solid 3px #ddd;
  box-sizing: border-box;

  padding: 10px;
}

.bodyimg>img {
  width: 100%;
  cursor: pointer;
}



/* * ----WRITE---- */
.cf_write {
  display: flex;
  flex-direction: column;
}

.cf_write>.head {
  display: flex;
  align-items: center;

  height: 30px;
  border-bottom: solid 1px #333;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.cf_write>.head>.title {
  font-size: 24px;
  font-weight: 600;
  /* font-family: var(--font-main); */
}

.cf_write>.head>.btn {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 75px;
  height: 100%;
  border: solid 1px #ddd;
  border-radius: 8px;

  margin-left: 10px;

  font-size: 14px;
  color: #222;
  font-family: var(--font-main);

  user-select: none;
  cursor: pointer;

  letter-spacing: .6px;
  word-spacing: 2px;
}

.cf_write>.head>.btn.black {
  color: #fff;
  background-color: #666;
  border-color: #666;
}

.cf_write>.head>.btn:hover {
  color: #fff;
  background-color: #999;
  border-color: #999;
  transition: all 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.cf_write>.head>.btn:active {
  transition: all 50ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  background-color: var(--color-high1);
  border-color: var(--color-high1);
}

.cf_write>.address {
  display: flex;
  flex-direction: column;

  width: 100%;
  box-sizing: border-box;

  padding: 20px 30px;

  border: solid 1px #ddd;
  border-radius: 16px;

  margin-bottom: 20px;
}

.cf_write>.address>.top {
  display: flex;
  align-items: center;

  margin-bottom: 20px;
}

.cf_write>.address select {
  display: flex;
  align-items: center;

  flex: 1 0 0;
  height: 45px;
  box-sizing: border-box;

  padding: 0 25px 0 11px;

  border: none;
  border-bottom: solid 1px #ddd;

  font-family: var(--font-sub);
  font-size: 18px;

  color: #333;

  user-select: none;
  cursor: pointer;
}

.cf_write>.address select:focus {
  outline: none;
}

.cf_write>.address input {
  width: 100%;
  height: 45px;
  box-sizing: border-box;

  padding: 13px 16px 11px;
  border-radius: 16px;
  background-color: #f5f6f8;
  border: solid 1px transparent;

  font-family: var(--font-sub);
  font-size: 18px;
  transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.cf_write>.address input:hover {
  box-shadow: var(--box-shadow-5);
}

.cf_write>.address input:focus {
  border-color: #ddd;
  outline: none;
  box-shadow: var(--box-shadow-5);
}

#editorBox .tox.tox-tinymce {
  height: 1000px !important;
}

/* !---- MODAL ----- */
#pop_done {
  display: none;

  width: 300px;
  height: 240px;
  
  position: fixed;
  left: calc(calc(100vw - 300px) / 2);
/*   top: calc(calc(100vh - 240px) / 2); */

  z-index: 9;
  transform: translate(0, 0);
}

.Cafe #pop_done {
  left: 0;
  bottom: 0;
/*   top: none; */
  width: calc(100vw -17px);
  height: calc(100vh - 365px);
}

#pop_done>.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #fff;

  transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

  border: solid 1px #ddd;

  user-select: none;
  cursor: pointer;
}

.Cafe #pop_done>.box {
  width: calc(100vw -17px);
  height: calc(100vh - 365px);
}

#pop_done>.box:hover {
  border-color: var(--color-high1);
  box-shadow: var(--box-shadow-3);
}

#pop_done>.box>.tt {
  font-size: 17px;
  line-height: 1.5;
  color: #222;
}

#pop_done .btn {
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    color: #222;
    cursor: pointer;
    display: flex;
    font-family: var(--font-main);
    font-size: 14px;
    height: 100%;
    justify-content: center;
    letter-spacing: .6px;
    margin-left: 10px;
    -webkit-user-select: none;
    user-select: none;
    width: 75px;
    height: 30px;
    word-spacing: 2px;
}

.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;

  .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid var(--color-high1);

    &::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px;
    }

    &::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }

    &::before,
    &::after {
      content: '';
      height: 100px;
      position: absolute;
      background: #FFFFFF;
      transform: rotate(-45deg);
    }

    .icon-line {
      height: 5px;
      background-color: var(--color-high1);
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.line-tip {
        top: 46px;
        left: 14px;
        width: 25px;
        transform: rotate(45deg);
        animation: icon-line-tip 0.75s;
      }

      &.line-long {
        top: 38px;
        right: 8px;
        width: 47px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.75s;
      }
    }

    .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(0, 198, 207, .5);
    }

    .icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: #FFFFFF;
    }
  }
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }

  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }

  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
