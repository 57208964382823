@font-face {
  font-family: 'SUIT-Medium';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SUIT-Regular';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

/* #2b66bd */
:root {
  --color-main: rgb(85, 87, 92);
  --color-main2: #1B1B1B;

  --color-high1: #00C6CF;
  --color-high2: #3565c9;
  --color-high3: #068488;
  --color-high4: #324B4C;

  --color-high-rgb: rgb(0, 198, 207);

  --font-main: 'SUIT-Medium';
  --font-sub: 'SUIT-Regular';

  --border-header: #828590;
  --border-main: #E7E6E8;
  --border-sub: #e4e8eb;
  --border-goo: #fcfcfc;
  --border-result: #e8ebee;

  --border-legoo: solid 1px rgba(233, 233, 236, 1);

  --js-color: #1a3866;

  --blue-color-1: #ccd9f6;
  --blue-color-2: #aac1f0;

  --chroma-green: rgb(0, 255, 27);

  --font-color-1: rgb(205, 205, 205);

  --height-menu: 45px;

  --box-shadow-b5: 0 2px 5px 1px rgb(64 60 67 / 16%);
  --box-shadow-2: -1px 0 6px rgb(0 0 0 / 20%);
  --box-shadow-3: 0 1px 6px rgb(32 33 36 / 28%);
  --box-shadow-4: 0 1px 6px rgb(32 33 36 / 18%);
  --box-shadow-5: 0 1px 6px rgb(32 33 36 / 8%);
  --box-shadow-3-hover: 0 1px 6px rgb(0 198 207 / 32%);
  --box-shadow-header-hover: 0 3px 6px rgb(0 198 207 / 32%);
  --box-shadow-b3: 0 1px 3px 0 rgba(0,0,0,.12);

  --home-left-width: 800px;
  --home-right-width: 350px;

  --transition-cubic: all 300ms cubic-bezier(.9, 0, .33, 1);
}

.App {
  font-family: var(--font-main);
}

.NewsBox {
  display: flex;
  justify-content: flex-start;
  position: relative;
  z-index: 0;
}

.News {
  display: flex;
  font-family: var(--font-sub);
  margin-top: 30px;
}

.News_mobile {
  align-items: center;
  justify-content: center;
}

.n_right {
  position: absolute;
  top: 0;
  right: 0;
  
  border: var(--border-legoo);
  border-radius: 16px;
  box-sizing: border-box;
  padding: 0 19px;
  width: 380px;
  margin-left: -380px;
  margin-top: 30px;
  background-color: #fff;
  height: fit-content;
  font-family: var(--font-sub);

  color: #345;
}

.n_right_blank {
  padding: 0 20px;
  width: 380px;
  margin-left: 20px;
  background-color: #fff;
  height: fit-content;
  box-sizing: border-box;
}

.nr_list {
  display: flex;
  align-items: center;
  
  height: 70px;
  box-sizing: border-box;
  
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 15px;
  margin: 10px 0;

  line-height: 1.3em;
  letter-spacing: -.2px;

  user-select: none;
  cursor: pointer;

  transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.nr_bb {
  border-bottom: var(--border-legoo);
}

.nr_img {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;
  background-color: var(--border-goo);
  border-radius: 50%;

  margin-right: 15px;
}

.nr_img>img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.nr_icon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 25px;
  height: 25px;

  border-radius: 50%;
  box-sizing: border-box;
  fill: var(--border-header);

  transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.nr_list:hover>.nr_icon {
  background-color: #00C6CF90;
  fill: #fff;
}

.nr_list .fBold {
  transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.nr_list:hover .fBold {
  color: var(--color-high1);
}

.n_left {
  width: 780px;
  overflow: hidden;
  background-color: #fff;
  border: var(--border-legoo);
  border-radius: 16px;
  box-sizing: border-box;
}

.n_left_mobile {
  margin-right: 20px;
  margin-left: 20px;
  width: calc(100vw - 40px);
  overflow: hidden;
  background-color: #fff;
  border: var(--border-legoo);
  border-radius: 16px;
  box-sizing: border-box;
}

.n_left_header {
  display: flex;
  align-items: center;
  padding: 19px;
  padding-bottom: 0;
}

.n_left_header_2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 19px;
  padding-bottom: 0;
}

.n_left_header_title {
  font-weight: 600;
  font-size: 20px;
  letter-spacing: .5px;
}

.news_list {
  margin: 19px;
  margin-bottom: 0;
  padding-bottom: 19px;
}

.news_header {
  display: flex;
  align-items: center;
  padding-bottom: 14px;
}

.news_img {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 22px;
  height: 22px;

  background-color: #f0f0f3;
  border-radius: 50%;

  margin-right: 8px;
  cursor: pointer;
}

.news_img>img {
  width: 70%;
  height: 70%;
}

.news_name:hover {
  cursor: pointer;
  text-decoration: underline;
}

.news_header_dot {
  font-size: 15px;
  font-family: var(--font-main);
  color: #939396;
  margin-right: 2.5px;
}

.news_header_pick {
  flex: 1 0 auto;
  font-size: 15px;
  color: #939396;
  margin-right: 2.5px;
}

.news_content {
  display: flex;
  align-items: center;
}

.news_title {
  font-size: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: var(--font-sub);
  max-width: 610px;
}

.news_body {
  margin-top: 4px;
  font-size: 18px;
  line-height: 1.6rem;
  max-height: 4.8rem;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;

  overflow: hidden;
  word-break: break-all;

  color: #282828;
}

.news_body_right {
  float: right;
  margin: 0 0 4px 16px;
}

.news_right_img {
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  border-radius: 12px;

  min-width: 114px;
  min-height: 114px;

  width: 114px;
  height: 114px;

  background-color: #f5f5f5;
}

.news_right_img>img {
  width: 100%;
  height: 100%;

  aspect-ratio: auto 114 / 114;
  overflow: clip;
  overflow-clip-margin: content-box;

  object-fit: cover;
  object-position: top;

  cursor: pointer;
  transition: all 0.2s linear;
}

.news_right_img>img:hover {
  /* animation: dot 1s infinite alternate ease; */
  transform: scale(1.1);  
}

.news_right_img>img:active {
  transform: scale(1.1);
}

@keyframes dot {
  100% {
    transform: scale(1.1);
    -webkit-transform:  scale(1.1);
  }
}

.news_more {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.news_more>div {
  display: flex;
  align-items: center;

  color: #555558;
  fill: #555558;
  font-size: 15px;
}

.news_more>div>svg {
  margin-left: 5px;
  height: 14px;
}

.news_more>div>img {
  height: 40px;
}

.news_bottom {
  display: flex;
  align-items: center;
  justify-content: center;

  border-top: var(--border-legoo);

  gap: 4px;
  width: 100%;
  padding: 16px 0;
  font-size: 1rem;
  line-height: 1.4rem;
  color: #444447;
}

.news_bottom:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
}

.n_left_header_bot {
  color: #647082;
  font-size: 16px;
}

#results_profile {
  padding-top: 16.8px;
  padding-bottom: 16.8px;
  /* background-color: rgba(159,134,105,0.1); */
}

#profile_name {
  font-weight: 600;
  font-size: 21px;
  margin-bottom:  5px;
}

#profile_btn_f {
  background-color: #9f8669;
  color: white;
}

.results_profile_t {
  display: flex;
  font-size: 18px;
}

.results_profile_t > div {
  margin-right: 20px;
}

.profile_hiperlink {
  color: #0068c3;
  padding-bottom: 5px;
}

.profile_lb {
 color: #917659;
}

#profile_menu {
  margin-top: 15px;
  display: flex;
  margin-bottom: 15px;
}

#profile_menu > div {
  padding: 7px 12px; 
  border-radius: 5px;
} 

#reulsts_main_head {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 600;
  /* margin-bottom: 5px; */
}

#reulsts_main_head_f {
  font-size: 20px;
  flex: 1 0 auto;
}

#reulsts_main_head_icon {
  width: 18px;
  height: 18px;
}

.gray_left {
  border-left: 3px solid var(--border-main);
  color: #8f8f8f;
  padding-left: 10px;
  padding-bottom: 7px;
}

.gray_left_2line {
  width: 62px;
  border-left: 3px solid var(--border-main);
  color: #8f8f8f;
  padding-left: 10px;
  padding-bottom: 35px;
  /* height: 100%; */
}

.gray_left_last {
  border-left: 3px solid var(--border-main);
  color: #8f8f8f;
  padding-left: 10px;
}

#result_container{
  display: flex;
}

#result_pic {
  height: 105px;
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
}

#result_pic > img {
  height: 100%;
  border-radius: 5px;
}

#results_main {
  margin: 8.4px;
  margin-bottom: 0;
  padding: 19px;
  border-top: 1px solid var(--border-main);
}

#results_footer {
  margin-top: 20px;
  border-top: solid 1px var(--border-main);
  padding-top: 15px;
  color: #8f8f8f;
}

a {
  text-decoration: none;
}

.Modal {
  display: none;
  flex-direction: column;
  
  position: fixed;
  top: 160px;
  right: calc(50% - 600px);
  width: 380px;
  max-height: 80%;
  box-sizing: border-box;

  border-radius: 16px;
  background-color: #fff;
  border: var(--border-legoo);
  overflow: hidden;

  box-shadow: 0 0 6px rgba(0,0,0,.05), 0 3px 6px rgba(0,0,0,.1);
}

#modal_img {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 500px;

  overflow: hidden;
}

#modal_img>img {
  width: 220%;
  object-fit: cover;
}

#modal_content {
  padding: 20px;
}

#modal_title {
  border-bottom: var(--border-legoo);
  font-size: 20px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

#modal_title:hover {
  cursor: pointer;
  text-decoration: underline;
}

#modal_body {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  line-clamp: 6;
  overflow: hidden;

  font-size: 16px;
  line-height: 1.5em;
  max-height: 9em;
  margin-bottom: 20px;
  
  cursor: pointer;
}

#modal_close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  width: 50px;
  height: 50px;
  border-radius: 50%;

  font-size: 1.4em;

  cursor: pointer;
}

#modal_close:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.ResultImages {
  width: 100%;
  margin-top: 20px;
  display: flex;
}

.result_image_0 {
  width: calc((100% / 3) - 12px);
  margin-right: 18px;
}

.result_image_end {
  width: calc((100% / 3) - 12px);
}

.result_image {
  width: 100%;

  display: flex;
  flex-direction: column;

  margin-bottom: 23px;
  cursor: pointer;
  transition: all 0.2s linear;
}

.result_image_box {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: fit-content;

  box-sizing: border-box;

  border-radius: 16px;
  border: solid 1px var(--border-main);
  overflow: hidden;

  margin-bottom: 10px;
}

.result_image_box>img {
  object-fit: cover;
  width: 100%;
}

.result_image_text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.result_image:hover .result_image_box {
  box-shadow: 0 6px 12px 0 rgba(0,0,0,.1),0 0 6px 0 rgba(0,0,0,.05);
}

.result_image:hover .result_image_text {
  text-decoration: underline;
}