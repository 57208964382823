.Home {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

#Home {
  display: flex;
  flex-direction: column;
}

.homeBox {
  width: 100%;
  min-height: 50px;
  margin-bottom: 20px;
}

.homeLeft {
  width: var(--home-left-width);
  max-width: var(--home-left-width);
  overflow: hidden;
  box-sizing: border-box;
  border: solid 1px var(--border-sub);
}

.homeRight {
  width: var(--home-right-width);
  max-width: var(--home-right-width);
  overflow: hidden;
  box-sizing: border-box;
  border: solid 1px var(--border-sub);
}

#home_1_banner {
  display: flex;
  align-items: center;
}

#home_1_banner > img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  object-position: center;
}

#home_1_banner:hover {
  cursor: pointer;
}

#login_box {
  background-color: var(--border-goo);
  display: flex;
  flex-direction: column;
}

#login_box_btn {
  flex: 1 0 auto;
  padding: 15px 0;
  margin: 15px 20px;
  background-color: var(--color-high1);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

#login_box_btn:hover {
  cursor: pointer;
}

#login_box_title {
  margin: 15px 20px 0px 20px ;
  font-size: 14px;
  color: var(--border-header);
}

#login_box_footer {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 15px;
  font-size: 14px;
  color: var(--border-header);
}

.login_box_footer_hover:hover {
  cursor: pointer;
  text-decoration: underline;
}

#home_2_left {
  display: flex;
  align-items: center;
  background-color: var(--border-goo);
}

#home_2_left_left {
  flex: 1 0 0;
  align-items: center;
  margin-left: 15px;
  overflow: hidden;
  box-sizing: border-box;
  padding-right: 15px;
}

#home_2_left_left_title {
  flex: 1 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#home_2_left_left_title:hover {
  cursor: pointer;
  text-decoration: underline;
}

#home_2_left_right {
  min-width: 180px;
  margin-right: 15px;
}

#home_2_left_dot {
  color: var(--border-header);
  margin: 0 5px;
}

#home_2_right {
  display: flex;
  align-items: center;
  background-color: var(--border-goo);
}

#home_2_right_f {
  border: solid 2px black;
  padding: 3px 8px;
  border-radius: 20px;
  font-size: 13px;
  margin-left: 15px;
}

#home_2_right_middle {
  color: var(--border-header);
}

#home_2_right>.tt {
  flex: 1 0;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#home_3_right {
  display: flex;
  align-items: center;
  border: solid 1px transparent;
}

#home_3_right >img {
  width: 100%;
}

#home_3_left {
  overflow: visible;
  display: flex;
  flex-direction: column;
  border: none;
}

#news_header {
  display: flex;
  margin-bottom: 20px;
}

.news_icons {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.news_icons > img {
  width: 80%;
  height: 80%;
}

#news_main {
  display: flex;

  box-sizing: border-box;
  width: 100%;

  flex: 1 0 auto;
}

#news_left {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  width: 200px;

  padding: 20px;
  border: solid 1px var(--border-sub);
  background-color: var(--border-goo);
}

.news_left_list {
  padding: 5px 15px;
  border-radius: 15px;
  margin-bottom: 7px;
  /* background-color: #00C6CF; */
}

.news_left_list:hover {
  text-decoration: underline;
  cursor: pointer;
}

.news_left_list_high {
  background-color: var(--color-high2);
  color: white;
  /* font-weight: 600; */
  box-shadow: var(--box-shadow-b5);
}

#news_right {
  position: relative;

  box-sizing: border-box;
  width: 600px;

  padding: 20px;
  display: flex;
  flex-direction: column;

  border-top: solid 1px var(--border-sub);
  border-bottom: solid 1px var(--border-sub);
}

#news_right_header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

#news_right_logo {
  height: 25px;
}

#news_right_header_btn {
  display: flex;
  padding: 5px 10px;
  font-size: 13px;
  border: solid 1px var(--border-sub);
  border-radius: 15px;
  background-color: var(--border-goo);
}

#news_right_header_btn:hover {
  cursor: pointer;
  color: var(--color-high1);
  font-weight: 600;
}

#news_right_main {
  overflow: hidden;
  display: flex;
  flex: 1 0 auto;
}

#news_sockbo {
  display: flex;
  flex-direction: column;
}

#news_sockbo>.top {
  display: flex;
  align-items: center;
}

#news_sockbo>.top:hover {
  cursor: pointer;
}

#news_sockbo>.top:hover>.t_box>.title {
  text-decoration: underline;
}

.top>.img_box {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 200px;
  margin-right: 20px;

  overflow: hidden;
}

.top>.img_box>img {
  width: 100%;
}

.top>.t_box {
  width: calc(100% - 220px);
}

.t_box>.title {
  font-size: 25px;
  margin-bottom: 10px;
}

#news_sockbo>.bot {
  margin-top: 15px;
}

#news_sockbo>.bot>.t {
  margin-top: 10px;
}

#news_sockbo>.bot>.t:hover {
  cursor: pointer;
  text-decoration: underline;
}

#news_right_main_left {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 220px;
  margin-right: 20px;
}

#news_title_img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 150px;
  margin-bottom: 10px;
}

#news_title_img>img {
  width: 100%;
  height: 100%;
}

#news_title_text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;

  margin: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: var(--font-sub);

  cursor: pointer;
}

#news_title_text:hover {
  text-decoration: underline;
}

#news_right_main_right {
  width: calc(100% - 240px);
  font-size: 15px;
  font-family: var(--font-main);
}

#news_right_main_right>div {
  height: calc(100%/8);

  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#news_right_main_right>div:hover {
  cursor: pointer;
  text-decoration: underline;
}

#news_arrow_box {
  position: absolute;

  width: 40px;
  height: 40px;

  right: -20px;
  top: calc(50% - 20px);
  display: flex;
  align-items: center;

  z-index: 10;
}

#news_arrow_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding-left: 2px;
  box-sizing: border-box;
  box-shadow: var(--box-shadow-3);
  color: gray;

  transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

#news_arrow_btn:hover {
  color: var(--color-high1);
  cursor: pointer;
  box-shadow: 0 1px 6px rgb(0 198 207 / 78%);;
}

#home_4_left {
  border: none;
}

#home_4_title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

#home_4_btn {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-family: var(--font-sub);
  height: 50px;
  border: solid 1px var(--border-sub);
  border-right: none;
  margin-bottom: 20px;
}

#home_4_btn>div {
  width: calc(100%/8);
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: solid 1px var(--border-sub);
  height: 100%
}

#home_4_btn_high {
  background-color: var(--color-high1);
  color: white;
  box-shadow: var(--box-shadow-3);
}

.home_4_content {
  display: flex;
  margin-bottom: 20px;
  cursor: pointer;
}

.home_4_content_img {
  width: 200px;
  height: 140px;
  overflow: hidden;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home_4_content_img>img {
  width: 100%;
}

.home_4_content_text {
  width: calc(100% - 220px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home_4_content:hover>.home_4_content_text>.h4c_title {
  text-decoration: underline;
}

.h4c_class {
  color: var(--color-high1);
  margin-bottom: 10px;
}

.h4c_title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  
  font-family: var(--font-sub);
  font-weight: 600;
  margin-bottom: 10px;
}

.h4c_body {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;

  font-size: 15px;
}

#home_4_right {
  display: none;
}

#login_box .user {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
}

.user .top,
.user .bot {
  display: flex;
  align-items: center;
}

.user .top {
  width: 100%;
  height: calc(100% / 3 * 2);
  padding: 0 15px;
  box-sizing: border-box;
}

.user .top .img {
  width: 60px; 
  height: 60px;

  border: solid 2px #00c5cf;
  border-radius: 50%;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  background-color: #00c5cfad;

  margin-right: 10px;
}

.user .top .img>svg {
  width: 55%;
  fill: #fff;
}

.user .top .info {
  flex: 1 0 auto;
}

.user .top .info .name {
  display: flex;
  align-items: center;
  color: #101010;
  position: relative;
}

.user .top .info .name .logout {
  cursor: pointer;
  border: solid 2px var(--border-main);
  color: #808080;
  font-size: 14px;
  line-height: 14px;

  padding: 8px 16px;
  position: absolute;
  right: 0;
  border-radius: 16px;
}

.user .top .info .name .high {
  font-weight: 600;
  color: #222;
  font-size: 18px;
}

.user .top .info .id {
  font-size: 14px;
  color: #101010;
  margin-top: 10px;
}

.user .bot {
  flex: 1 0 auto;
}

.user .bot .btns {
  width: calc(100% / 4);
  height: 100%;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
  border-top: solid 1px var(--border-main);
  border-right: solid 1px var(--border-main);
  background-color: #f3f5f7;
  user-select: none;
  cursor: pointer;

  font-family: var(--font-sub);
  font-weight: 600;
}

.user .bot .btns:hover {
  color: var(--color-high1);
  background-color: #f3f5f7c0;;
}

.user .bot .btns.end {
  border-right: 0;
}