.Shop {
  display: flex;
  flex-direction: column;
  margin-top: 25px;

  border: var(--border-legoo);
  border-radius: 16px;
}

#shop_img {
  display: flex;
  align-items: center;
  /* justify-content: center; */

  border-radius: 16px 16px 0 0;
  margin: -1px -1px 0;

  overflow: hidden;

  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 1);
}

.shop_img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.shop_img > img {
  width: 100%;
}

#shop_name {
  padding: 16px 19px;
  letter-spacing: -.3px;
  line-height: 2rem;
  word-break: break-all;

  border-bottom: var(--border-legoo);
}

#shop_name_main {
  font-size: 22px;
  margin-right: 4px;
  font-weight: 600;
  font-family: var(--font-sub);
  color: rgba(34, 34, 37, 1);
  cursor: pointer;
  line-height: 2.5rem;
  letter-spacing: -.3px;
}

#shop_name_span {
  display: inline-block;
  vertical-align: top;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 16.5px;
  font-weight: 300;
  color: #939396;
}

#shop_name_bot {
  display: flex;
  align-items: center;
  font-size: 15px;
  letter-spacing: -.3px;
}

#shop_star {
  display: flex;
  align-items: center;
  justify-content: center;
}

#shop_star > svg {
  width: 14px;
  height: 14px;
  fill: #fc4c4e;
}

#shop_score {
  color: rgba(34, 34, 37, 1);
  margin-left: 4px;
  font-weight: 600;
  font-family: var(--font-sub);
}

.shop_name_dot {
  color: #444447;
}

.shop_name_bot_t {
  color: #444447;
}

#shop_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 19px;
  letter-spacing: -.3px;
  word-break: break-all;
  color: rgba(34, 34, 37, 1);

  border-bottom: var(--border-legoo);
}

.shop_btn {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #555558;
  line-height: 1.9rem;
  text-align: center;
  cursor: pointer;
  letter-spacing: -.3px;
}

.shop_btn_t {
  margin-top: 5px;
}

.shop_btn_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #555558;
}

.shop_btn_icon > svg {
  width: 18px;
  height: 18px;
}

.shop_right_border {
  border-right: var(--border-legoo);
}

#shop_desc {
  padding: 19px;

  border-bottom: var(--border-legoo);
}

.shop_desc {
  display: flex;
  align-items: center;
}

.shop_desc_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  margin-right: 8px;
}

.shop_desc_icon > svg {
  height: 15px;
  fill: #b6b6b9;
}

.shop_desc_t {
  font-family: var(--font-sub);
  font-weight: 300;
}

#shop_menu {
  padding: 16px 19px;
  border-bottom: var(--border-legoo);
}

.shop_header {
  display: flex;
  align-items: center;
  margin-bottom: 19px;
  word-break: break-all;
  line-height: 2.4rem;
}

.shop_header_1 {
  flex: 1 0 auto;
  color: rgba(34, 34, 37, 1);
  font-size: calc(11px * 1.6);
  font-weight: 600;
  /* letter-spacing: 2px; */
}

.shop_header_2 {
  color: #0c43b7;
  font-size: calc(11px * 1.5);
}

.shop_header_2:hover {
  cursor: pointer;
  text-decoration: underline;
}

#shop_menu_arr {
  display: flex;
  align-items: center;
}

.shop_menu {
  width: calc(100% / 6);
  display: flex;
  flex-direction: column;

  padding: 0 6px;
  float: left;
  box-sizing: border-box;
}

.shop_menu_img {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  overflow: hidden;
}

.shop_menu_img > img {
  /* border-radius: 16px; */
  width: 100%;
}

.shop_menu_name {
  white-space: nowrap;
  color: rgba(34, 34, 37, 1);
  line-height: 2.1rem;
  margin-top: 8px;

  overflow: hidden;
  text-overflow: ellipsis;
}

.shop_menu_v {
  font-weight: 600;
}

.shop_menu_v_won {
  margin-left: 2px;
  font-weight: 300;
}

#shop_reviews {
  padding: 16px 19px;
  border-bottom: var(--border-legoo);
}

#shop_reviews_title {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.shop_reviews_title {
  display: flex;
  align-items: center;

  padding: 0 11px;
  border: var(--border-legoo);
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  background-clip: padding-box;
  letter-spacing: -.2px;
  line-height: 38px;

  color: #222225;

  margin-right: 8px;
}

.shop_reviews_title_icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.shop_reviews_title_icons > svg {
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #1ba5b3;
}

.shop_reviews_title_text {
  font-family: var(--font-sub);
  font-weight: 600;
  margin-right: 8px;
}

.shop_reviews_title_num {
  color: #1ba5b3;
  /* font-family: var(--font-main); */
  font-weight: 600;
}

.shop_reviews_user_chunk {
  display: flex;
  align-items: center;
  padding-top: 8px;
}

.shop_reviews_user {
  width: calc(100% / 3);
  padding: 16px;

  display: flex;
  align-items: center;

  border: var(--border-legoo);
  border-radius: 8px;
  overflow: hidden;
  height: 200px;
  box-sizing: border-box;
}

.shop_reviews_user:hover {
  cursor: pointer;
  border: solid 1px rgba(203, 203, 206, 1);
}

.shop_reviews_user_box {
  display: flex;
  flex-direction: column;
  /* width: calc(50% - 5px - 16px); */
  max-width: calc(50% - 5px);
  height: 100%;
  margin-right: 5px;
}

.shop_reivews_user_info {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.shop_reviews_user_info_name {
  color: rgba(34, 34, 37, 1);
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  padding-top: 16px
}

.shop_reviews_user_container {
  display: flex;
  align-items: center;
}

.shop_reviews_user_info_box {
  display: flex;
  color: #555558;
  flex: 1 0 auto;
}

.shop_reviews_user_text {
  margin-bottom: 24px;
}

.shop_reviews_user_img {
  display: flex;
  align-items: center;
  justify-content: center;

  /* width: calc(50% - 16px); */
  width: 50%;
  border-radius: 16px;
  overflow: hidden;
}

.shop_reviews_user_btn {
  font-size: 13px;
  color: #555558;
  cursor: pointer;
}

.shop_reviews_user_btn > span:hover {
  text-decoration: underline;
}

.shop_reviews_user_img > img {
  width: 100%;
}

#shop_more {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  color: #444447;
}

#shop_more:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, .05);
}