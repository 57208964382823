.WebHardHome {
  display: flex;
  align-items: center;
  height: calc(100vh - var(--common-header-height));
  max-height: calc(100vh - var(--common-header-height));

  overflow: hidden;

  font-family: var(--font-sub);
}

.WebHardMain {
  display: flex;
  flex-direction: column;

  height: 100%;
  max-height: 100%;
  flex: 1 0 auto;
  max-width: calc(100% - 180px);

  overflow-x: hidden;
  overflow-y: scroll;

  background-color: #F8FAFD;
  
  box-sizing: border-box;
  padding: 10px;
}

.WebHardMain>.header {
  display: flex;
  align-items: center;

  /* background-color: #fff; */

  height: 40px;
  margin-bottom: 10px;
}

.WebHardMain>.header>.btns {
  display: flex;
  align-items: center;

  background-color: #E6F2FE;
  overflow: hidden;
  border-radius: 5px;
  height: 100%;
  margin-right: 10px;

  font-family: var(--font-main);
}

.WebHardMain>.header>.btns.end {
  margin-right: 0;
}

.WebHardMain>.header>.btns>div {
  display: flex;
  align-items: center;
  justify-content: center;
  
  height: 100%;
  min-width: 40px;
  line-height: 0;
  padding: 10px 8px;
  box-sizing: border-box;

  font-size: 14px;

  user-select: none;
}

.WebHardMain>.header>.btns>div>svg {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 16px;
}

.WebHardMain>.header>.btns>div:hover {
  cursor: pointer;
  background-color: #dee9f5;
}

.WebHardMain>.row {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 300px;
  padding: 25px;

  box-sizing: border-box;
}

.WebHardMain>.row>.square {
  display: flex;
  flex-direction: column;

  min-height: 100%;
  max-height: 100%;
  min-width: 200px;
  max-width: 200px;

  overflow: hidden;
  box-sizing: border-box;

  user-select: none;
  cursor: pointer;
}

.WebHardMain>.row>.square:hover>.content {
  /* transition: all 200ms cubic-bezier(.9, 0, .33, 1); */
  background-color: #E6F2FE;
}

.WebHardMain>.row>.square>.content {
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;

  height: 200px;
  width: 200px;

  box-sizing: border-box;
  border-radius: 10px;

  border-radius: 10px;
  border: solid 1px var(--border-main);
}

.WebHardMain>.row>.square>.content>div {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 75px;
  height: 75px;
  overflow: hidden;

  border-radius: 50%;
}

.WebHardMain>.row>.square>.content>div>svg {
  height: 50%;
  fill: #fff;
}

.WebHardMain>.row>.square>.content>.video {
  background-color: #F28B82;
}

.WebHardMain>.row>.square>.content>.video>svg {
  margin-top: 1px;
  margin-left: 8px;
}

.WebHardMain>.row>.square>.text {
  height: 50px;
  width: 200px;

  overflow: hidden;
}

.WebHardMain>.row>.square>.text>.title {
  text-align: center;
  vertical-align: middle;
  width: 100%;
  max-width: 100%;
  height: 50%;

  padding: 5px;
  box-sizing: border-box;

  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 15px;
  line-height: 15px;
}

.WebHardMain>.row>.square>.text>.volume {
  text-align: center;
  vertical-align: middle;
  width: 100%;
  max-width: 100%;
  height: 50%;

  padding: 5px;
  box-sizing: border-box;

  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 12.5px;
  line-height: 12.5px;
  color: #9398a0;
}