#m_body {
  display: flex;
  flex-direction: column;

  flex: 1 0 auto;
  padding-left: 25px;
  padding-right: 8px;

  box-sizing: border-box;
  max-width: 100vw;

  overflow-y: scroll;

  height: calc(100vh - 66px);
  max-height: calc(100vh - 66px);
}

#m_body.tablet {
  padding-left: 20px;
  padding-right: 0;
}

#m_b_t {
  display: flex;
  align-items: center;
  height: 45px;
  min-height: 45px;

  /* color: var(--js-color); */
  font-weight: bold;

  margin-bottom: 15px;
}

.tablet>#m_b_t {
  padding-left: 10px;
  box-sizing: border-box;
  max-width: 100%;
}

#m_b_title {
  font-size: 24px;
}

.m_b_s {
  display: flex;
  align-items: center;
  /* height: 50px; */
  font-size: 16px;

  margin-left: 32px;

  margin-bottom: 8px;

  max-width: 800px;
}

.m_b_s_f {
  display: flex;
  align-items: center;

  padding: 5px 12px;
  background-color: #e6f2fe;
  border-radius: 14px;

  margin-right: 15px;
}

.m_b_h {
  margin-right: 15px;
}

.m_b_s_n {
  margin-right: 5px;
}

.m_b_s_e {
  color: var(--border-header);
}

.m_b_s_r {
  font-size: 12px;
  text-decoration: underline;
  color: lightgray;
  cursor: pointer;
}

.m_b_s_r:hover {
  color: var(--yj-main);
}

.m_b_t_s {
  width: 17px;
  fill: var(--border-main);
  margin-right: 15px;
}

.m_f_btn_i {
  margin-right: 5px;
}

.m_f_btn_i>img {
  width: 15px;
}

.m_b_b {
  flex: 1 0 0;
  margin-top: 20px;
  padding-left: 20px;
  font-size: 18px;
  line-height: 1.8em;
  margin-bottom: 20px;

  box-sizing: border-box;

  overflow-x: hidden;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
}

.m_b_b::-webkit-scrollbar {
  background-color: none;
  width: 12px;
  height: 12px;
}

.m_b_b::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

.m_b_b::-webkit-scrollbar-corner {
  background-color: transparent;
}

.m_b_b::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset 1px 1px 1px rgba(0,0,0,.25);
}

.m_b_b::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset 1px 1px 1px rgba(0,0,0,.25);
}

.m_b_b::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 1px 1px 1px rgba(0,0,0,.1),
  inset 0px -1px 0px rgba(0,0,0,.07);
}

.tablet .m_b_b {
  padding-right: 20px;
  max-width: 100%;
}

.m_f {
  padding-left: 30px;
  display: flex;
}

.m_f_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  width: 80px;
  border: solid 1px var(--border-goo);
  border-radius: 5px;
  margin-right: 10px;
}

.m_f_btn_t {
  color: var(--border-header);
}

.m_f_btn:hover {
  /* background-color: var(--border-goo); */
  cursor: pointer;
  color: black;
}

#file {
  border-top: solid 1px var(--border-goo);
  /* border-top: solid 1px var(--border-goo); */
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  margin-top: 10px;
  padding-top: 15px;
}

.tablet>#file {
  box-sizing: border-box;
  padding-left: 15px;
  margin-right: 0;

  max-width: 100%;
}

#file_header {
  display: flex;
}

.file_up {
  width: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

#file_tail {
  /* margin-left: 10px; */
  display: flex;
  width: 800px;
  max-width: 100%;
}

.tablet>#file>#file_tail {
  box-sizing: border-box;
  padding-right: 30px;
}

#file_tail_box {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  border: solid 1px var(--border-goo);
  padding: 9px 0px;
}

.file_tail_c {
  display: flex;
  padding: 5px 19px;
  align-items: center;
}

.file_tail_c:hover {
  /* background-color: rgba(171,176,179,0.2); */
  color: var(--yj-main);
  font-weight: 600;
  cursor: pointer;
}

.file_bold {
  font-weight: bold;
}

.file_blue {
  color: #924dbf;
}

.file_gray {
  color: rgb(146,146,148);
}

.file_lightgray {
  color: rgba(171,176,179,0.2);
}

.mr_15 {
  margin-right: 15px;
}

.ml_15 {
  margin-left: 15px;
}

.mr_10 {
  margin-right: 10px;
}

.mr_5 {
  margin-right: 5px;
}

.mr_1 {
  margin-right: 10px;
}

.file_i {
  width: 15px;
  height: 15px;
}

.file_blank {
  flex: 1 0 auto;
}

.file_font_small {
  font-size: small;
}

.file_first_i {
  padding: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--yj-main);
}

#m_t {
  display: flex;
  padding-top: 20px;
  margin-bottom: 20px;
  padding-left: 20px;
}

#m_t > div {
  color: var(--yj-main);
  margin-right: 40px;
}

.post_slide {
  position: relative;

  width: 680px;
  max-width: calc(100% - 60px);
  margin-top: 30px;
  margin-left: 20px;
  margin-bottom: 300px;
  /* overflow: hidden; */

  /* box-shadow: var(--box-shadow-3); */
}

.tablet>.post_slide {
  max-width: calc(100% - 100px);
  margin-left: 50px;
  margin-right: 10px;
}

.slick-slide div {
  outline: none;
}

.slide_img {
  width: 100%;
  box-shadow: var(--box-shadow-3);
}

.slide_img>img {
  width: 100%;
}

.post_dots {
  position: absolute;
  bottom: 15px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.post_dots li {
  position: relative;
  display: inline-block;
  width: 5px;
  height: 10px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.post_dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 5px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.post_dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 10px;
  content: '•';
  text-align: center;
  opacity: .75;
  color: #fff;
  -webkit-font-smoothing: antialiased;
}

.post_dots li.slick-active button:before {
  opacity: 1;
  color: #0095f6;
}

.s_btn {
  position: absolute;
  top: calc(50% - 20px);
  font-size: 40px;
  line-height: 40px;
  color: #aaa;
  cursor: pointer;
  user-select: none;
}

.s_btn.left {
  left: 0;
  margin-left: -40px;
}

.s_btn.right {
  right: 0;
  margin-right: -40px;
}

.s_btn:hover {
  color: var(--yj-main);
}

.mailSet_br {
  height: 1.8em;
}

.mailSet_line {
  margin-top: .9em;
  margin-bottom: .9em;

  height: 1px;
  background-color: #222;

  width: 100%;
}

.m_b_s_t {
  font-size: 14px;
  color: #666;
  flex: 1 0 auto;
  text-align: end;
  margin-right: 32px;
}

.m_b_b_2box {
  display: flex;

}

.m_b_b_2box>.i {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  width: 70%;
  margin-right: 20px;
  overflow: hidden;
}

.m_b_b_2box>.i>img {
  width: 100%;
}

.mail_reply {
  display: flex;
  align-items: center;
}

.mail_reply>.btn {
  display: flex;
  align-items: center;
  justify-content: center;

  border: solid 1px var(--border-main);

  height: 36px;
  box-sizing: border-box;

  padding: 0 16px;
  border-radius: 36px;

  margin-right: 10px;

  user-select: none;
  cursor: pointer;
}

.mail_reply>.btn:hover {
  box-shadow: var(--box-shadow-3);
}

.mail_reply>.btn:active {
  background-color: var(--border-main);
}

.mail_reply>.btn>svg {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 18px;
  height: 18px;

  margin-right: 10px;
}