.MailMain {
  display: flex;
  flex-direction: column;

  height: 100%;
  max-height: 100%;
  flex: 1 0 auto;
  max-width: calc(100% - 180px);

  overflow: hidden;

  background-color: #F8FAFD;
  /* background-color: #000; */
  
  box-sizing: border-box;
  padding: 10px;
}

.MailMain.true {
  max-width: 100%;
}

.MailMain>.header,
.MailMain>.m_header_box>.header {
  display: flex;
  align-items: center;

  /* background-color: #fff; */

  height: 40px;

  margin: 0 5px 10px 5px;
}

.MailMain>.header>.directory,
.MailMain>.m_header_box>.header>.directory {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 20px;
  margin-left: 10px;
  font-family: var(--font-main);
  /* font-weight: 600; */

  cursor: pointer;
  user-select: none;

  height: 100%;

  border-radius: 10px;
  overflow: hidden;

  padding: 0 15px;
}

.MailMain>.header>.directory:hover,
.MailMain>.m_header_box>.header>.directory:hover {
  background-color: #fff;
  box-shadow: var(--box-shadow-5);
}

.MailMain>.header>.btns,
.MailMain>.m_header_box>.header>.btns {
  display: flex;
  align-items: center;

  background-color: #E6F2FE;
  overflow: hidden;
  border-radius: 5px;
  height: 100%;
  margin-right: 10px;

  font-family: var(--font-main);

  user-select: none;
}

.MailMain>.header>.btns.end,
.MailMain>.m_header_box>.header>.btns.end {
  margin-right: 0;
}

.MailMain>.header>.btns>span,
.MailMain>.m_header_box>.header>.btns>span {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 10px;
  margin-left: -7px;
  margin-right: 13px;
}

.MailMain>.header>.btns>div,
.MailMain>.m_header_box>.header>.btns>div {
  display: flex;
  align-items: center;
  justify-content: center;
  
  height: 100%;
  min-width: 40px;
  line-height: 0;
  padding: 10px 8px;
  box-sizing: border-box;

  font-size: 14px;

  user-select: none;
}

.MailMain>.header>.btns>div>svg,
.MailMain>.m_header_box>.header>.btns>div>svg {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  /* transition: 100ms linear; */
}

.MailMain>.header>.btns.px18>div>svg,
.MailMain>.m_header_box>.header>.btns.px18>div>svg {
  width: 18px;
  height: 18px;
}

.MailMain>.header>.btns>div:hover,
.MailMain>.m_header_box>.header>.btns>div:hover {
  cursor: pointer;
  transition: 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  background-color: #dee9f5;
}

.MailMain>.header>.btns>div:hover>svg,
.MailMain>.m_header_box>.header>.btns>div:hover>svg {
  width: 18px;
  height: 18px;
}

.MailMain>.header>.btns.px18>div:hover>svg,
.MailMain>.m_header_box>.header>.btns.px18>div:hover>svg {
  width: 20px;
  height: 20px;
}

.MailMain>.header>.btns.add_span:hover,
.MailMain>.m_header_box>.header>.btns.add_span:hover {
  cursor: pointer;
  background-color: #dee9f5;
}

.MailMain>.header>.line,
.MailMain>.m_header_box>.header>.line {
  height: 60%;
  width: 1px;
  background-color: var(--border-main);
  margin: 0 10px;
}

.mail_box {
  position: relative;
  
  display: flex;
  flex-direction: column;

  flex: 1 0 0;

  background-color: #fff;
  margin: 5px;

  box-sizing: border-box;
  padding: 10px 0;

  overflow: hidden;

  border-radius: 10px;
}

.mail_con {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;

  flex: 1 0 0;

  /* padding-right: 10px; */

  overflow-x: hidden;
  overflow-y: scroll;
}

.mail_con::-webkit-scrollbar {
  background-color: none;
  width: 12px;
  height: 12px;
}

.mail_con::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

.mail_con::-webkit-scrollbar-corner {
  background-color: transparent;
}

.mail_con::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset 1px 1px 1px rgba(0,0,0,.25);
}

.mail_con::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset 1px 1px 1px rgba(0,0,0,.25);
}

.mail_con::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 1px 1px 1px rgba(0,0,0,.1),
  inset 0px -1px 0px rgba(0,0,0,.07);
}

.mail_col {
  position: relative;

  display: flex;
  align-items: center;

  height: 40px;
  min-height: 40px;

  box-sizing: border-box;
  padding: 9px;

  border: solid 1px transparent;
  border-top: solid 1px var(--border-main);

  user-select: none;

  fill: #999;
  color: #202124;

  /* transition: linear; */
}

.mail_col.mobile {
  height: 100px;
  min-height: 100px;
  flex-direction: column;
  align-items: flex-start;
}

.mail_col.mobile>.head {
  width: 100%;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  height: 20px;
}

.mail_col.end {
  border-bottom: solid 1px var(--border-main);
}

.mail_col:hover {
  cursor: pointer;
  border: solid 1px var(--border-main);
  box-shadow: 0px 0px 6px rgba(61, 93, 189, 0.2);
  fill: #333;
}

.mail_col>.box {
  display: flex;
  align-items: center;

  height: 40px;
  flex: 1 0 0;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mail_col>.box.mobile {
  flex-direction: column;
  height: 60px;
  padding: 5px 0;
  box-sizing: border-box;
}

.mail_col>.icon,
.mail_col>.head>.icon,
.toggle_box>.icon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  margin: 0 5px;
}

.mail_col>.head>.icon.checkbox {
  margin-left: 0;
}

.mail_col>.icon>.icon_box,
.mail_col>.head>.icon>.icon_box,
.toggle_box>.icon>.icon_box {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 18px;
  min-height: 18px;

  border-radius: 50%;
}

.mail_col>.icon>.icon_box:hover,
.mail_col>.head>.icon>.icon_box:hover,
.toggle_box>.icon>.icon_box:hover {
  min-width: 38px;
  min-height: 38px;

  background-color: rgba(0, 0, 0, .05);
  transition: 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.mail_col>.icon>.icon_box>svg,
.mail_col>.head>.icon>.icon_box>svg,
.toggle_box>.icon>.icon_box>svg {
  width: 16px;
  height: 16px;
}

.mail_col>.icon.bookmark>.icon_box>svg,
.mail_col>.head>.icon.bookmark>.icon_box>svg {
  height: 14px;
}

.mail_col>.box>.title {
  margin-left: 10px;
  margin-right: 30px;

  width: 160px;

  box-sizing: border-box;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-size: 16px;
}

.mail_col.mobile>.head>.title {
  margin-left: 10px;
  flex: 1 0 0;
  box-sizing: border-box;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-size: 16px;
}

.mail_col>.box>.body {
  flex: 1 0 0;

  box-sizing: border-box;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mail_col>.box.mobile>.body {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 25px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.mail_col.unread {
  font-weight: 600;
  /* color: #000; */
  /* font-family: var(--font-main); */
}

.toggle_box {
  display: none;
  align-items: center;

  height: 100%;

  margin-left: 20px;
}

.mail_col:hover>.toggle_box {
  display: flex;
}