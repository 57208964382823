.topPadding {
  margin-top: 80px;
}

.w1200 {
  width: 1200px;
}

.w100per {
  width: 100%;
}

.dFlex {
  display: flex;
}

.fCenter {
  align-items: center;
  justify-content: center;
}

.blank350 {
  width: 350px;
}

.fBold {
  font-weight: 600;
}

.fBlank {
  flex: 1 0 auto;
}

.fHigh {
  color: var(--color-high1);
}

.fHigh2 {
  color: var(--color-high2);
}

.fHigh3 {
  color: var(--color-high3);
}

.fHigh4 {
  color: var(--color-high4);
}

.fGray {
  color: var(--border-header);
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr20 {
  margin-right: 20px;
}

.f13 {
  font-size: 13px;
}

.f14 {
  font-size: 14px;
}

.f15 {
  font-size: 15px;
}

.blankFlex {
  flex: 1 0 auto;
}

.mb10 {
  margin-bottom: 10px;
}

.mb19 {
  margin-bottom: 19px;
}

.mb20 {
  margin-bottom: 20px;
}

.mr20 {
  margin-right: 20px;
}

.ml20 {
  margin-left: 20px;
}

.textUnder {
  cursor: pointer;
  color: #0c43b7;
}

.textUnder:hover {
  text-decoration: underline;
}

.mr8 {
  margin-right: 8px;
}

.curPointer {
  cursor: pointer;
}

.hoverUnder:hover {
  text-decoration: underline;
}

.pw10 {
  padding-left: 10px;
  padding-right: 10px;
}

/* * Common Header */

/* transition: all 200ms cubic-bezier(.9, 0, .33, 1); */