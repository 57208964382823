.Result {
  position: relative;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - (132px + 150px + 1px));
  border-top: solid 1px #e8ebee;
  background-color: #fafbfc;
}

#Result {
  position: relative;

  display: flex;
  flex-direction: column;
}

.ResultContent {
  display: flex;
  flex-direction: column;
  margin: 9px 0 0;
  border: solid 1px #e8ebee ;
  border-radius: 6px;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,.03);
  font-size: 16px;
  letter-spacing: -.25px;
  background-color: #fff;
  padding: 20px 156px 20px 36px;
  justify-content: center;
}

.result_content_title {
  margin-bottom: 10px;
  font-weight: 600;
  font-family: var(--font-sub);
  color: var(--color-high2);
}

.ResultContent:hover {
  cursor: pointer;
}

.ResultContent:hover > .result_content_title {
  font-weight: 600;
}

#result_more {
  display: flex;
  margin: 9px 0 0;
  border: solid 1px #e8ebee ;
  border-radius: 6px;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,.03);
  font-size: 16px;
  letter-spacing: -.25px;
  background-color: #f7f9fa;
  padding: 20px 36px;
  align-items: center;
}

#result_question {
  display: flex;
  flex-direction: column;
  /* height: 47px; */
  margin: 9px 0 0;
  border: solid 1px #e8ebee ;
  border-radius: 6px;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,.03);
  font-size: 16px;
  line-height: 25px;
  /* text-align: center; */
  letter-spacing: -.25px;
  background-color: #fff;
  /* align-items: center; */
  justify-content: center;
  padding: 20px 36px;
}

#result_pages {
  display: flex;
  margin: 9px 0 0;
  border: solid 1px #e8ebee ;
  border-radius: 6px;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,.03);
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -.25px;
  background-color: #fff;
  align-items: center;
  padding: 20px 36px;
  color: var(--border-header);
}

#reulst_pages_num {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
}

#reulst_pages_num>div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
}

#result_none_title {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
}

#result_none_title > span {
  color: #fa3830;
  margin-right: 3px;
}

.result_none {
  margin-bottom: 10px;
}

.margin_2vh {
  margin-left: 16.8px;
}

#results_profile {
  padding-top: 16.8px;
  padding-bottom: 16.8px;
  /* background-color: rgba(159,134,105,0.1); */
}

.NewsSide {
  position: fixed;
  top: 160px;
  left: calc(50% - 750px);
  width: 130px;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 16px;
  background-color: #fff;
  border: var(--border-legoo);
  font-size: 18px;
}

.news_side_title {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 15px;
  border-bottom: var(--border-legoo);
  letter-spacing: 1px;
}

.news_side_btn {
  padding-top: 15px;
  cursor: pointer;
  color: #555;
}

.news_side_btn:hover {
  text-decoration: underline;
  color: #000;
}

.news_side_btn_high {
  color: var(--color-high2);
  font-weight: 600;
}