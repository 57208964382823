.tox-statusbar__branding,
.tox-statusbar__text-container,
.tox-statusbar__resize-handle {
  display: none !important;
}

.tox-statusbar__wordcount,
.tox-statusbar__path {
  user-select: none;
}

#tinyEditor_container {
  display: flex;
  flex-direction: column;

  position: absolute;
  left: 100vw;
  bottom: 10px;
  width: calc(100% - 20px);
  height: 400px;

  background-color: #fff;

  z-index: 5;

  transition: 500ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

#tinyEditor_container.getcon {
  left: 10px;
}

.tox.tox-tinymce {
  height: 350px !important;
}

#tinyEditor_container>.btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-top: 10px;
  height: 40px;
}

#tinyEditor_container>.btns>.btn {
  display: flex;
  align-items: center;
  height: 38px;

  padding: 0 20px;

  border-radius: 30px;
  border: solid 1px var(--border-main);

  margin-left: 10px;

  transition: 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);

  user-select: none;
  cursor: pointer;
}

#tinyEditor_container>.btns>.btn>svg {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  margin-right: 10px;
}

#tinyEditor_container>.btns>.btn.send:hover {
  background-color: #0b57d04b;
  border-color: transparent;
}

#tinyEditor_container>.btns>.btn.send:active {
  background-color: #0b57d0;
  color: #fff;
  fill: #fff;
  transition: 0ms none;
}

#tinyEditor_container>.btns>.btn.delete:hover {
  background-color: var(--border-header);
  border-color: transparent;
}

#tinyEditor_container>.btns>.btn.delete:active {
  background-color: #555;
  color: #fff;
  fill: #fff;
  transition: 0ms none;
}

#message_sent_toast {
  position: fixed;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 200px;
  bottom: 50px;
  left: 100vw;

  box-sizing: border-box;

  z-index: 10;

  background-color: #202124;
  padding: 20px;
  border-radius: 10px 0 0 10px;
  color: #fff;

  transition: 400ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

#message_sent_toast.toast_load {
  left: calc(100vw - 200px);
}

#message_sent_toast.toast_done {
  background-color: #606164;
}