.Header {
  display: flex;
  align-items: center;
  justify-content: center;
}

#header160 {
  height: 160px;
}

#header60 {
  height: 80px;
}

.header_hover_high {
  border-bottom: solid 1px var(--color-high1);
  background-color: white;
  position: fixed;
  top: 0px;
  z-index: 99;
}

.header_hover_high:hover {
  box-shadow: var(--box-shadow-header-hover);
}

.header_hover_high.onFocus {
  box-shadow: var(--box-shadow-header-hover);
}

#logo_box {
  display: flex;
  align-items: center;
}

#logo_text {
  font-family: var(--font-sub) !important ;
  font-size: 40px;
  font-weight: 900;
  color: var(--color-high1);
  margin-left: 10px;
  margin-right: 30px;
  text-shadow: var(--box-shadow-3-hover);
}

#logo_bar {
  margin-left: 15px;
  margin-right: 5px;
  height: 35px;
  border-right: solid 1px var(--border-sub);
}

#logo {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#logo:hover {
  cursor: pointer;
}

#logo > img {
  height: 90%;
  width: 90%;
}

#search_box {
  flex: 1 0 auto;
  padding: 11px 13px;
  display: flex;
  align-items: center;
  /* box-shadow: var(--box-shadow-3); */
}

.search_box_home {
  border: solid 2px var(--color-high1);
}

#search {
  border: none;
  height: 24px;
  font-family: var(--font-main);
  font-size: 18px;
  font-weight: 600;
  flex: 1 0 auto;
}

#search:focus {
  outline: none;
  border: none;
}

/* #search:active #search_box {
  box-shadow: var(--box-shadow-3);
} */

.search_icons {
  height: 18px;
  width: 18px;
  margin-left: 10px;
}

#search_icon {
  width: 50px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-high1);
}

.search_icon_no {
  background-color: transparent !important;
}

#search_icon:hover,
#search_icon_n:hover {
  cursor: pointer;
}

#search_icon>img,
#search_icon_n>img {
  width: 50%;
  height: 50%;
}