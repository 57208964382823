.WebHard>.intro {
  width: 100%;
  max-width: 100%;

  height: 100vh;
  max-height: calc(100vh - 60px);

  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.WebHard>.intro>.box {
  flex: 1 0 auto;

  display: flex;
  align-items: center;
  
  width: 70vw;
  max-width: 1400px;
}

.WebHard>.intro>.box>.text {
  box-sizing: border-box;
  width: 50%;
  padding-right: 30px;
}

.WebHard>.intro>.box>.text>.t1 {
  font-size: 75px;
  font-family: var(--font-sub);
  margin-bottom: 30px;
}

.WebHard>.intro>.box>.text>.t2 {
  font-size: 20px;
  word-spacing: 3px;
  color: #444;
  font-family: var(--font-sub);
  line-height: 30px;
}

.WebHard>.intro>.box>.text>.btns {
  margin-top: 30px;
  display: flex;
  align-items: center;
  margin-left: -20px;
}

.WebHard>.intro>.box>.img {
  box-sizing: border-box;

  width: 50%;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
}

.WebHard>.intro>.box>.img>img {
  width: 100%;
}

.WebHard>.intro>.foot {
  box-sizing: border-box;
  border-top: solid 1px var(--border-main);
  width: 100%;

  padding-top: 20px;

  display: flex;
  flex-direction: column;

  align-items: center;
  margin-bottom: 20px;

  font-family: var(--font-main);

  cursor: pointer;

  color: #666;
}

.WebHard>.intro>.foot>.btn {
  font-size: 30px;
}