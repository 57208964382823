.MiddleBar {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  border-top: solid 1px var(--border-sub);
  box-shadow: var(--box-shadow-b3);
  position: relative;

  user-select: none;
}

.MiddleBarMobile {
  display: flex;
  align-items: center;
  height: 50px;
}

#MiddleBar {
  font-size: 16px;
  overflow-x: scroll;
  align-items: center;
  justify-content: flex-start;
  width: 1200px;
  box-sizing: border-box;
}

.MiddleBar_mobile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
}

#MiddleBar>div {
  min-width: fit-content;
  cursor: pointer;
}

#MiddleBar::-webkit-scrollbar {
  display: none;
}

#middleBar_weather {
  font-size: 14px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-family: var(--font-sub);
}

.mW_f {
  margin-right: 5px;
}

.mW_i {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.mW_e {
  font-size: 15px;
  font-weight: 600;
  margin-right: 5px;
}

.mW_i > img {
  width: 80%;
  height: 80%;
}

.mW_b {
  color: var(--border-sub);
  margin-right: 5px;
}

.mW_m {
  margin-left: 5px;
  font-size: 12px;
  color: var(--border-header);
}

.middleBar_start {
  margin-left: 20px;
}

#MiddleBar .newMiddle {
  display: flex;
  align-items: center;
  font-size: 15px;
  background-color: rgba(0, 0, 0, .05);
  padding: 0 14px;
  height: 36px;
  border-radius: 36px;
  box-sizing: border-box;
  color: #444;
  margin-right: 10px;
}

#MiddleBar .newMiddle:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, .08);
}

#MiddleBar .newMiddle>svg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: fit-content;
  margin-right: 8px;
  fill: #888;
}

#MiddleBar .newMiddle>svg>path {
  height: 100%;
}

#middlebar_right_btn {
  position: absolute;
  left: calc(50% + 550px);
  height: 36px;
  width: 100px;

  background-color: #fff;

  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
  border-radius: 50px;
}

#middlebar_right_btn .btn {
  height: 36px;
  width: 36px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: solid 1px var(--border-main);
  border-radius: 50%;
  z-index: 5;
}

#middlebar_right_btn .btn:hover {
  background-color: rgba(0, 0, 0, .05);
  cursor: pointer;
}

#MiddleBar .page_1_blank {
  width: 50px;
}

.newHigh,
.newHigh>svg {
  background-color: var(--color-high1) !important;
  color: #fff !important;
  fill: #fff !important;
}