.LoginModal {
  display: none;
  align-items: center;
  justify-content: center;

  position: fixed;
  left: 0;
  top: 0;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, .5);

  z-index: 9999;

  font-family: var(--font-sub);

  box-shadow: var(--box-shadow-3);
}

.lbox {
  position: relative;

  display: flex;
  flex-direction: column;

  padding: 40px 20px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #fff;

  width: 400px;
  align-items: center;

  margin-bottom: 200vh;

  transition: all 500ms cubic-bezier(.9, 0, .33, 1);
}

.lbox.show {
  margin-bottom: 0;
}

.lbox>.lrow {
  display: flex;
  align-items: center;

  width: 100%;

  margin-bottom: 20px;
}

.lbox>.lrow.checkbox {
  cursor: pointer;
  user-select: none;
}

.lbox>.lrow.q {
  cursor: pointer;
  font-size: 14px;
  color: #666;

  user-select: none;

  margin-top: 20px;
  margin-bottom: 0;
}

.lbox>.lrow.q:hover {
  text-decoration: underline;
}

.lrow>.loginInput {
  display: flex;
  align-items: center;

  border: none;

  transition: all 200ms linear;
  outline: solid 1px transparent;

  box-sizing: border-box;
  width: 100%;
  height: 50px;
  background-color: #E8F0FD;

  font-family: var(--font-sub);
  padding: 10px;
  font-size: 20px;

  border-radius: 5px;
}

.lrow>.loginInput::placeholder {
  font-size: 16px;
  /* color: var(--color-high2); */
}

.lrow>.loginInput:focus {
  outline: solid 1px var(--color-high2);
}

#loginValid {
  position: relative;

  display: flex;
  flex-direction: column;
  padding: 10px;

  justify-content: center;
  align-items: flex-start;

  height: 60px;
  box-sizing: border-box;
  margin-top: 10px;
  margin-bottom: 0;

  color: #fff;
}

.lbox>.lrow.no {
  color: #CA0000 !important;
  background-color: #FCECEC;
}

.lbox>.lrow.vibe>div {
  animation-name: vibe;
  animation-duration: 100ms;
  animation-iteration-count: infinite;
}

@keyframes vibe {
  0% {
    margin-left: -3px;
  }

  50% {
    margin-left: 0px;
  }

  100% {
    margin-left: 3px;
  }
}

#login_btn {
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 200ms linear;
  cursor: pointer;
  color: #fff;
  height: 50px;
  margin-top: 30px;
  margin-bottom: 10px;
  border-radius: 5px;

  background-color: var(--color-high2);

  user-select: none;

  font-family: var(--font-main);
  font-size: 18px;
}

#login_btn:hover {
  background-color: var(--color-high1);
}

#login_btn:active {
  background-color: var(--color-high2);
}

#loginTry {
  opacity: 0;

  position: absolute;
  left: calc(50% + 7.5px);
  top: calc(50% - 17.5px);
}

.loader1 {
  position: relative;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  border: 3px solid var(--color-high2);

  top: 28%;
  top: -webkit-calc(50% - 43px);
  top: calc(50% - 43px);
  left: 35%;
  left: -webkit-calc(50% - 43px);
  left: calc(50% - 43px);

  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: loader1 1s linear infinite;
  animation: loader1 1s linear infinite;
}

.loader1:after {
  content: "";
  position: absolute;
  top: -2px;
  left: 20px;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #fff;

  /* background-color: var(--color-high2);
  opacity: .5; */
}

@-webkit-keyframes loader1 {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loader1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}