#modal_chroma_full {
  display: none;

  position: fixed;
  left: 0;
  top: 0;

  width: 100vw;
  height: 100vh;

  z-index: 9999;

  cursor: pointer;
  user-select: none;
}